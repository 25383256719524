import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"

const ReturnPolicy = () => {
  return (
    <>
      <Layout>
        <Seo title="Return Policy" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-semibold">
                  Softmatter Returns Policy
                </div>
              </div>
              <div className=" my-7">
                <div className="mb-4 font-firs-medium">
                  Softmatter noes not offers refunds or replacements for
                  toolkits.
                </div>
                <div className="mb-4">
                  If you believe your units are  defective, please send us an
                  email to softmatter@masholdings.com  with a video and/or an
                  image showcasing the defect. We will get back to you within 7
                  working days with our response.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ReturnPolicy
